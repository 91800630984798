import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  CardHeader,
  Table,
  Input,
} from "reactstrap";
import Swal from "sweetalert2";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import axios from "./../axios";
import { SERVER_NAME } from "config";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

function Dashboard() {
  const [range, setDateRange] = useState(["", ""]);
  const [patients, setPatient] = useState([]);
  const [doctors, setDoctor] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();

  useEffect(() => {
    getAllDoctors();
  }, []);

  useEffect(() => {
    getPatients();
  }, [range, searchQuery, currentPage]);

  const saveFile = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("id", e.target.name);

    try {
      const { data } = await axios.post("/add/report", formData);

      if (data.success) {
        Swal.fire({
          icon: "success",
          text: data.msg,
        });
      }

      getPatients();
    } catch (error) {
      console.error(error);
    }
  };

  const getAllDoctors = async () => {
    try {
      const { data } = await axios.get("/view/doctor");
      setDoctor(data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPatients = async (page) => {
    try {
      const startDate = range[0] || "all";
      const endDate = range[1] || "all";

      const { data } = await axios.get(
        `/view/patients/${startDate}/${endDate}?page=${page ? page : currentPage}&limit=15&search=${searchQuery}`
      );

      const { totalPatients, todayPatient, totalCenter, totalDoctor, totalPages } = data;
      setDashboard({ totalPatients, todayPatient, totalCenter, totalDoctor, totalPages });
      setPatient(data?.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  const assignDoctor = async (event) => {
    const [doctorId, assignedDoctorName, id] = event.target.value.split("-");

    try {
      const { data } = await axios.post("/assign/patients", {
        doctorId,
        assignedDoctorName,
        id,
      });
      if (data.success) {
        Swal.fire({
          icon: "success",
          text: data.msg,
        });
      }
      getPatients();
    } catch (error) {
      console.error(error);
    }
  };

  const handleTimeChange = async (event) => {
    const { name, value } = event.target;

    try {
      const { data } = await axios.post("/add/time", {
        id: name,
        time: value,
      });
      if (data.success) {
        Swal.fire({
          icon: "success",
          text: data.msg,
        });
      }
      getPatients();
    } catch (error) {
      console.error(error);
    }
  };

  const onDateChange = (date) => {
    setDateRange(date);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleEdit = (id) => {
    history.push(`/admin/edit-patient/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/patient/delete/${id}`);
      if (data.success) {
        Swal.fire({
          icon: "success",
          text: data.msg,
        });
        getPatients();
      } else {
        Swal.fire({
          icon: "error",
          text: data.msg,
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "An error occurred while deleting the patient.",
      });
    }
  };
  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  
    try {
      const response = await axios.get(`/view/patients/${range[0] || "all"}/${range[1] || "all"}?page=${currentPage}&limit=15&search=${query}`);
      const { data } = response;
      const { totalPatients, todayPatient, totalCenter, totalDoctor, totalPages } = data;
      setDashboard({ totalPatients, todayPatient, totalCenter, totalDoctor, totalPages });
      setPatient(data?.data || []);
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <>
      <div className="content">
      <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-globe text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Doctor</p>
                      <CardTitle tag="p">{dashboard.totalDoctor}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Doctor count
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Centers</p>
                      <CardTitle tag="p">{dashboard.totalCenter}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-calendar" /> Center Count
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Patients</p>
                      <CardTitle tag="p">{dashboard.totalPatients}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> Total Patient
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Today's Patient</p>
                      <CardTitle tag="p">{dashboard.todayPatient}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update now
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "auto" }}>
                    <CardTitle style={{ display: "inline" }} tag="h4">
                      Registered Patients
                    </CardTitle>
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                  <div>
                    <DateRangePicker onChange={onDateChange} value={range} />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Action</th>
                      <th>Patient ID</th>
                      <th>Patient Name</th>
                      <th>Age/Gender</th>
                      <th>Center Name</th>
                      <th>Reasons</th>
                      <th>Doctor</th>
                      <th>Doctor Reference</th>
                      <th>Date</th>
                      <th>Report</th>
                      <th>Prescription</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patients.map(
                      ({
                        age = "",
                        centerName = "",
                        gender = "",
                        name = "",
                        patientId = "",
                        _id = "",
                        time = "",
                        report = "",
                        doctorReference = {},
                        prescriptionReport = "",
                        reasonForScan = "",
                        doctorReferenceFromUser = "",
                        comment = "",
                      }) => (
                        <tr key={_id}>
                          <td>
                            <button
                              className="btn btn-info btn-sm"
                              onClick={() => handleEdit(_id)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDelete(_id)}
                            >
                              Delete
                            </button>
                          </td>
                          <td>{patientId}</td>
                          <td>{name}</td>
                          <td>
                            {age}/{gender}
                          </td>
                          <td>{centerName}</td>
                          <td>{reasonForScan}</td>
                          <td>
                            <select
                              onChange={assignDoctor}
                              value={`${doctorReference.doctorId}-${doctorReference.doctorName}-${_id}`}
                            >
                              <option value="0">Select Doctor:</option>
                              {doctors.map(({ _id: doctorId = "", name = "" }) => (
                                <option key={doctorId} value={`${doctorId}-${name}-${_id}`}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>{doctorReferenceFromUser}</td>
                          <td style={{ minWidth: "120px" }}>
                            <Input
                              name={_id}
                              onBlur={handleTimeChange}
                              placeholder={time || "Add Date"}
                              type="text"
                            />
                          </td>
                          <td style={{ minWidth: "140px" }}>
                            {report ? (
                              <a
                                target="_blank"
                                href={report?.includes("amazonaws") ? report : `${SERVER_NAME}/public/${report}`}
                              >
                                View Report
                              </a>
                            ) : (
                              <input
                                type="file"
                                className="form-control"
                                name={_id}
                                onChange={saveFile}
                              />
                            )}
                          </td>
                          <td>
                            {prescriptionReport && (
                              <a
                                target="_blank"
                                href={
                                  prescriptionReport?.includes("amazonaws")
                                    ? prescriptionReport
                                    : `${SERVER_NAME}/public/${prescriptionReport}`
                                }
                              >
                                View Report
                              </a>
                            )}
                          </td>
                          <td>{comment}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={dashboard.totalPages}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        </div>{" "}
      </div>
    </>
  );
}

export default Dashboard;
