import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import axios from "./../../axios";

function Tables() {
  const [list, setList] = useState([]);

  useEffect(() => {
    getAllDoctors();
  }, []);

  const getAllDoctors = async () => {
    try {
      const { data } = await axios.get("/view/doctor");
      setList(data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDoctor = async (id) => {
    try {
      const { data } = await axios.get(`/delete/doctor/${id}`);
      getAllDoctors();
      Swal.fire({
        icon: "success",
        text: data.msg,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4" style={{ display: "inline" }}>
                  Registered Doctors
                </CardTitle>
                <Button
                  style={{ float: "right" }}
                  color="primary"
                  onClick={() =>
                    (window.location.href = `${window.location.origin}/admin/add-doctor`)
                  }
                >
                  Add Doctors{" "}
                </Button>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>S No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Specialization</th>
                      <th className="text-right">Edit</th>
                      <th className="text-right">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map(
                      (
                        {
                          _id = "",
                          name = "",
                          email = "",
                          phoneNumber = "",
                          specializations = "",
                        },
                        index
                      ) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{name}</td>
                            <td>{email}</td>
                            <td>{phoneNumber}</td>
                            <td>{specializations}</td>
                            <td className="text-right">
                              <Button
                                size="sm"
                                onClick={() =>
                                  (window.location.href = `${window.location.origin}/admin/edit-doctor?doctor=${_id}`)
                                }
                                color="info"
                              >
                                Edit
                              </Button>
                            </td>
                            <td className="text-right">
                              {" "}
                              <Button
                                size="sm"
                                onClick={() => deleteDoctor(_id)}
                                color="danger"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
