import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Swal from "sweetalert2";

import axios from "./../../axios";

function User() {
  const [values, setValues] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    specializations: "",
  });

  const urlParams = new URLSearchParams(window.location.search);
  const doctorId = urlParams.get("doctor");

  useEffect(() => {
    getDoctorDetails();
  }, []);

  const getDoctorDetails = async () => {
    try {
      const { data } = await axios.get(`/view/particularDoctor/${doctorId}`);

      const [firstArr = {}] = data?.data || [];
      setValues({
        name: firstArr.name,
        specializations: firstArr.specializations,
        phoneNumber: firstArr.phoneNumber,
        email: firstArr.email,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async () => {
    const { name, specializations, phoneNumber, email } = values;
    if (!name || !specializations || !phoneNumber || !email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Fill All the Input Fields",
      });

      return;
    }
    try {
      const { data } = await axios.post("edit/doctor", {
        ...values,
        id: doctorId,
      });

      Swal.fire({
        icon: "success",
        text: data.msg,
      });

      setTimeout(() => {
        window.location.href = `${window.location.origin}/admin/doctor-page`;
      }, 1500);
    } catch (error) {}
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Edit Doctor</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Doctor Name</label>
                        <Input
                          value={values.name}
                          placeholder="Doctor Name"
                          type="text"
                          name="name"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Doctor Name</label>
                        <Input
                          value={values.name}
                          placeholder="Doctor Name"
                          type="text"
                          name="name"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          placeholder="Email"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          type="email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Phone Number</label>
                        <Input
                          value={values.phoneNumber}
                          placeholder="Phone Number"
                          name="phoneNumber"
                          onChange={handleChange}
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Specializations</label>
                        <Input
                          value={values.specializations}
                          name="specializations"
                          onChange={handleChange}
                          placeholder="Specializations"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        onClick={handleFormSubmit}
                      >
                        Edit Doctor
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default User;
