import React, { useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Swal from "sweetalert2";

import axios from "./../../axios";

function User() {
  const [values, setValues] = useState({
    name: "",
    specializations: "",
    phoneNumber: "",
    email: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async () => {
    const { name, specializations, phoneNumber, email } = values;
    if ((!name && !specializations && !phoneNumber) || !email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Fill All the Input Fields",
      });

      return;
    }

    try {
      const { data } = await axios.post("add/doctor", values);
      Swal.fire({
        icon: "success",
        text: data.msg,
      });

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {}
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Add New Doctor</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Doctor Name</label>
                        <Input
                          placeholder="Doctor Name"
                          type="text"
                          name="name"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Phone Number</label>
                        <Input
                          placeholder="Phone Number"
                          name="phoneNumber"
                          onChange={handleChange}
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                          type="email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Specializations</label>
                        <Input
                          name="specializations"
                          onChange={handleChange}
                          placeholder="Specializations"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        onClick={handleFormSubmit}
                      >
                        Add Doctor
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default User;
