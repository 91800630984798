import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  CardHeader,
  Table,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import axios from "./../axios";
import { SERVER_NAME } from "config";
import ReactPaginate from "react-paginate";

function Dashboard() {
  const [patients, setPatient] = useState([]);
  const [range, setDateRange] = useState(["", ""]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getPatients();
  }, [range]);

  const getPatients = async (page) => {
    try {
      const startDate = range[0] || "all";
      const endDate = range[1] || "all";
      const centerDetails = JSON.parse(localStorage.getItem("user-details"));
      const { data } = await axios.get(
        `/view/center/patients/${centerDetails._id}/${startDate}/${endDate}?page=${page ? page : currentPage}&limit=15`
      );
      setPatient(data?.data);
      setPaginationData(data.pagination);
    } catch (error) {
      console.error(error);
    }
  };

  const onDateChange = (date) => {
    setDateRange(date);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
    getPatients(e.selected + 1);
  };
  const handleSearch = async () => {
    try {
      const centerDetails = JSON.parse(localStorage.getItem("user-details"));
      const { data } = await axios.get(
        `/view/center/patients/${centerDetails._id}/${range[0] || "all"}/${range[1] || "all"}?page=${currentPage}&limit=15&search=${searchQuery}`
      );
      setPatient(data?.data);
      setPaginationData(data.pagination);
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle style={{ display: "inline" }} tag="h4">
                  Registered Patients
                </CardTitle>
                <div style={{ float: "right", marginTop: "10px" }}>
                  <input
                    type="text"
                    placeholder="Search by Patient ID or Name"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      handleSearch(); // Call handleSearch on every change in the input field
                    }}
                    style={{ marginRight: "10px",height:"30px" }}
                  />
                  <DateRangePicker onChange={onDateChange} value={range} />
                </div>{" "}
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>S . No</th>
                      <th>Patient ID</th>
                      <th>Patient Name</th>
                      <th>Patient Age</th>
                      <th>Patient Gender</th>
                      <th>Center Name</th>
                      <th>Reason For Scan </th>
                      <th>Reference</th>
                      <th>Report Updated</th>
                      <th>Admin Report </th>
                      <th>Prescription </th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patients.map(
                      (
                        {
                          age = "",
                          centerName = "",
                          gender = "",
                          name = "",
                          patientId = "",
                          report = "",
                          prescriptionReport = "",
                          time = "",
                          reasonForScan = "",
                          doctorReferenceFromUser = "",
                          comment = "",
                        },
                        index
                      ) => {
                        return (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td>{patientId}</td>
                            <td>{name}</td>
                            <td>{age}</td>
                            <td>{gender}</td>
                            <td>{centerName}</td>
                            <td>{reasonForScan}</td>
                            <td>{doctorReferenceFromUser}</td>
                            <td>{time}</td>
                            <td>
                              {report && (
                                <a
                                  target="_blank"
                                  href={
                                    report?.includes("amazonaws")
                                      ? report
                                      : `${SERVER_NAME}/public/${report}`
                                  }
                                >
                                  {" "}
                                  View Report{" "}
                                </a>
                              )}
                            </td>
                            <td>
                              {prescriptionReport && (
                                <a
                                  target="_blank"
                                  href={
                                    prescriptionReport?.includes("amazonaws")
                                      ? prescriptionReport
                                      : `${SERVER_NAME}/public/${prescriptionReport}`
                                  }
                                >
                                  {" "}
                                  View Report{" "}
                                </a>
                              )}
                            </td>{" "}
                            <td>{comment}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={paginationData.totalPages}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>{" "}
    </>
  );
}

export default Dashboard;
