import axios from "axios";
import { SERVER_NAME } from "config";

const api = axios.create({
  baseURL: `${SERVER_NAME}/api/admin/`,
});

api.defaults.headers.common = {
  authorization: `Bearer ${localStorage.getItem("token")}`,
};

export default api;
