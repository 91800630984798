import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Swal from "sweetalert2";

import axios from "../../axios";

function User() {
  const [values, setValues] = useState({
    name: "",
    code: "",
    email: "",
    password: "",
    centerName: "",
    centerAddress: "",
  });

  const urlParams = new URLSearchParams(window.location.search);
  const centerId = urlParams.get("center");

  useEffect(() => {
    getCenterDetails();
  }, []);

  const getCenterDetails = async () => {
    try {
      const { data } = await axios.get(`/view/particularCenter/${centerId}`);
      const [firstArr = {}] = data.data;
      setValues({
        name: firstArr.name,
        code: firstArr.code,
        centerName: firstArr.centerName,
        centerAddress: firstArr.centerAddress,
        email: firstArr.email,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async () => {
    const { name, code, centerName, centerAddress, email } = values;
    if (!name || !code || !centerAddress || !centerName || !email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Fill All the Input Fields",
      });

      return;
    }
    try {
      const { data } = await axios.post("edit/center", {
        ...values,
        id: centerId,
      });

      Swal.fire({
        icon: "success",
        text: data.msg,
      });

      setTimeout(() => {
        window.location.href = `${window.location.origin}/admin/center-page`;
      }, 1500);
    } catch (error) {}
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Edit Center</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Center Name</label>
                        <Input
                          defaultValue=""
                          placeholder="Center Name"
                          type="text"
                          value={values.centerName}
                          name="centerName"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue=""
                          placeholder="Password"
                          type="password"
                          value={values.password}
                          name="password"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          defaultValue=""
                          placeholder="Email"
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Center Code</label>
                        <Input
                          placeholder="Center Code"
                          name="code"
                          value={values.code}
                          onChange={handleChange}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Center Address</label>
                        <Input
                          name="centerAddress"
                          value={values.centerAddress}
                          onChange={handleChange}
                          placeholder="Center Address"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>User Name</label>
                        <Input
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          placeholder="User Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        onClick={handleFormSubmit}
                      >
                        Update Center
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default User;
