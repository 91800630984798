// LoadingSpinner.js
import React from "react";
import { Spinner } from "reactstrap";

const LoadingSpinner = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999 // Ensure it's above other elements
      }}
    >
      <Spinner style={{ width: "5rem", height: "5rem" }} color="primary" />
    </div>
  );
};

export default LoadingSpinner;
