import Dashboard from "views/Dashboard.js";

import viewCenter from "./views/Centers/viewCenter";
import addCenter from "./views/Centers/addCenter";
import editCenter from "./views/Centers/editCenter";

import addDoctor from "./views/Doctors/addDoctor";
import editDoctor from "./views/Doctors/editDoctor";
import viewDoctors from "./views/Doctors/viewDoctors";

import addPatient from "./views/Patients/addPatient";
import centerDashboard from "./views/centerDashboard";
const userRole = localStorage.getItem("role");

var routes = [
  ...(userRole === "admin"
    ? [
        {
          path: "/dashboard",
          name: "Dashboard",
          icon: "nc-icon nc-bank",
          component: Dashboard,
          layout: "/admin",
          display: true,
        },
        {
          path: "/doctor-page",
          name: "Doctors",
          icon: "nc-icon nc-single-02",
          component: viewDoctors,
          layout: "/admin",
          display: true,
        },
        {
          path: "/add-doctor",
          name: "Add Doctor",
          icon: "nc-icon nc-single-02",
          component: addDoctor,
          layout: "/admin",
          display: false,
        },
        {
          path: "/edit-doctor",
          name: "edit Doctor",
          icon: "nc-icon nc-single-02",
          component: editDoctor,
          layout: "/admin",
          display: false,
        },
        {
          path: "/center-page",
          name: "Centers",
          icon: "nc-icon nc-single-02",
          component: viewCenter,
          layout: "/admin",
          display: true,
        },
        {
          path: "/add-center",
          name: "Add Centers",
          icon: "nc-icon nc-single-02",
          component: addCenter,
          layout: "/admin",
          display: false,
        },
        {
          path: "/edit-center",
          name: "Centers",
          icon: "nc-icon nc-single-02",
          component: editCenter,
          layout: "/admin",
          display: false,
        },
        {
          path: "/edit-patient/:id",
          name: "Add Patient",
          icon: "nc-icon nc-single-02",
          component: addPatient,
          layout: "/admin",
          display: false,
        },
      ]
    : []),

  ...(userRole === "center"
    ? [
        {
          path: "/dashboard",
          name: "Dashboard",
          icon: "nc-icon nc-bank",
          component: centerDashboard,
          layout: "/admin",
          display: true,
        },
        {
          path: "/add-patient",
          name: "Add Patient",
          icon: "nc-icon nc-single-02",
          component: addPatient,
          layout: "/admin",
          display: true,
        },
        
      ]
    : []),
];
export default routes;
