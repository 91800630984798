import React, { useState } from "react";
import Swal from "sweetalert2";

import axios from "./../../axios";

import "./style.scss";

function Login() {
  if (localStorage.getItem("token"))
    window.location.href = `${window.location.origin}/admin/dashboard`;

  const [values, setValues] = useState({
    name: "",
    password: "",
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    const { name, password } = values;
    if (!name || !password) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Fill All the Input Fields",
      });

      return;
    }

    try {
      const { data = {} } = await axios.post("login", values);
      if (!data.success) {
        Swal.fire({
          icon: "error",
          text: data.msg,
        });
        return;
      }

      localStorage.setItem("token", data.token);
      localStorage.setItem("role", data?.user?.role);
      localStorage.setItem("user-details", JSON.stringify(data?.user));
      window.location.href = `${window.location.origin}/admin/dashboard`;
    } catch (error) {}
  };

  return (
    <div id="login-page-css">
      <div class="main-wrapper">
        <form action="GET">
          <div class="form-cont-1">
            <h1>Log In</h1>
            <p>Admin / Center </p>
          </div>
          <div class="form-cont-2">
            <p>Username</p>
            <input
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Email your username"
            />
          </div>
          <div class="form-cont-2">
            <p>Password</p>
            <input
              name="password"
              onChange={handleChange}
              type="password"
              value={values.password}
              placeholder="Password"
            />
          </div>

          <button
            onClick={handleFormSubmit}
            style={{ float: "right" }}
            type="button"
            class="button-35"
          >
            Log In{" "}
          </button>
        </form>

        <div class="float circle c-1"></div>
        <div class="float circle c-2"></div>
        <div class="float circle c-3"></div>
        <div class="float line l-1"></div>
        <div class="float line l-2"></div>
        <div class="float line l-3"></div>
      </div>
      <div class="main-wrapper2">
        <div class="border-line"></div>
      </div>
    </div>
  );
}

export default Login;
