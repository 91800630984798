// User.js
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Button, Card, CardHeader, CardBody, CardTitle, FormGroup, Form, Input, Row, Col, Progress } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../axios";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoadingSpinner from "./LoadingSpinner";

function User() {
  const location = useLocation();
  let params = useParams();
  const history = useHistory();

  const [values, setValues] = useState({
    name: "",
    age: "",
    gender: "",
    image: "",
    doctorReferenceFromUser: "",
    comment: "",
    patientId: ''
  });
  const [selected, setSelected] = useState([]);
  const [fileUploading, setFileUploading] = useState(false); // State to track file uploading status
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to control the disabled status of the Add Patient button
  const [isLoading, setIsLoading] = useState(false); // State to track overall loading status
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const getPatienDetails = async (id) => {
    const { data } = await axios.get(`/view/patient/${id}`);
    console.log(data);
    let formVal = data.data
    setValues({
      name: formVal.name,
      age: formVal.age,
      gender: formVal.gender,
      image: formVal.image,
      doctorReferenceFromUser: formVal.doctorReferenceFromUser,
      comment: formVal.comment,
      patientId: formVal.patientId
    });
  }

  useEffect(() => {
    if (params.id) {
      getPatienDetails(params.id)
    }
  }, [])

  console.log('llll', location, params);
  const handleFormSubmit = async () => {
    const { name, age, gender, doctorReferenceFromUser } = values;

    if (!params.id) {
      if (
        !name ||
        !age ||
        !gender ||
        !doctorReferenceFromUser ||
        selected.length === 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Fill All the Input Fields",
        });

        return;
      }
    } else {
      if (
        !name ||
        !age ||
        !gender
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Fill All the Input Fields",
        });

        return;
      }
    }

    try {
      setIsLoading(true); // Start loading
      const centerDetails = JSON.parse(localStorage.getItem("user-details"));

      const formData = new FormData();

      formData.append("name", values.name);
      formData.append("age", values.age);
      formData.append("gender", values.gender);
      formData.append(
        "doctorReferenceFromUser",
        values.doctorReferenceFromUser
      );
      formData.append("comment", values.comment);
      if (!params.id) {
        formData.append("image", values.image);
        formData.append(
          "reasonForScan",
          selected.map(({ value = "" }) => value)
        );
        formData.append("centerId", centerDetails._id);
        formData.append("centerCode", centerDetails.code);
        formData.append("centerName", centerDetails.centerName);
      }
      let sendData = {}
      if (params.id) {
        sendData = { name: values.name, age: values.age, gender: values.gender, comment: values.comment, patientId: values.patientId ,
          doctorReferenceFromUser: values.doctorReferenceFromUser

        }
      }

      let data
      if (!params.id) {
        setIsButtonDisabled(true); // Disable the button when submitting the form
        
        // Set up axios to track progress
        const config = {
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((loaded * 100) / total);
            setUploadProgress(progress);
          }
        };
        
        data = await axios.post("add/patient", formData, config);
        console.log(data)
        if (data.data.success) {
          Swal.fire({
            icon: "success",
            text: data.data.msg,
          });
        }
      } else {
        data = await axios.post(`edit/patient/${params.id}`, sendData);
        console.log(data)
        history.push(`/admin/dashboard`);
        if (data.data.success) {
          Swal.fire({
            icon: "success",
            text: "Record Updated Successfully",
          });
        }
      }

      setIsLoading(false); // Stop loading
      setUploadProgress(0); // Reset progress
      
      //timeout comment
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setIsLoading(false); // Stop loading on error
      setUploadProgress(0); // Reset progress on error
    }
  };

  const saveFile = (e) => {
    setValues({
      ...values,
      ["image"]: e.target.files[0],
    });
  };

  const options = [
    { label: "PRE-IMPLANT", value: "PRE-IMPLANT" },
          { label: "VIRTUAL IMPLANT PLANNING", value: "VIRTUAL IMPLANT PLANNING" },
          { label: "POST-IMPLANT EVALUATION", value: "POST-IMPLANT EVALUATION" },
          { label: "ENDODONTIC EVALUATION", value: "ENDODONTIC EVALUATION" },
          { label: "IMPACTED - CANINE - MOLAR - OTHERS", value: "IMPACTED - CANINE - MOLAR - OTHERS" },
          { label: "AIRWAY ANALYSIS", value: "AIRWAY ANALYSIS" },
          { label: "MIDPALATAL SUTURE EVALUATION (MPS)", value: "MIDPALATAL SUTURE EVALUATION (MPS)" },
          { label: "ORTHODONTIC EVALUATION", value: "ORTHODONTIC EVALUATION" },
          { label: "TMJ EVALUATION", value: "TMJ EVALUATION" },
          { label: "PATHOLOGY EVALUATION", value: "PATHOLOGY EVALUATION" },
          { label: "PAIN EVALUATION", value: "PAIN EVALUATION" },
          { label: "PEDIATRIC EVALUATION", value: "PEDIATRIC EVALUATION" },
          { label: "PARANASAL SINUSES/SINONASAL COMPLEX", value: "PARANASAL SINUSES/SINONASAL COMPLEX" },
          { label: "3D CEPH ANALYSIS", value: "3D CEPH ANALYSIS" },
          { label: "OPG", value: "OPG" },
          { label: "OPG WITH IMPLANT", value: "OPG WITH IMPLANT" },
          { label: "CEPH ANALYSIS", value: "CEPH ANALYSIS" },
          { label: "OTHER 2D RADIOGRAPHS", value: "OTHER 2D RADIOGRAPHS" },
          { label: "PRE & POST OPERATIVE EVALUATION", value: "PRE & POST OPERATIVE EVALUATION" },
          { label: "THESIS/RESEARCH PURPOSE", value: "THESIS/RESEARCH PURPOSE" },
          { label: "ORTHOGNATHIC SURGERY", value: "ORTHOGNATHIC SURGERY" },
          { label: "POST-OPERATIVE EVALUATION", value: "POST-OPERATIVE EVALUATION" },
          { label: "OTHERS", value: "OTHERS" }
  ];

  return (
    <>
      <div className="content">
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5"> {params.id ? 'Edit' : 'Add'} Patient</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      {params.id &&
                        <Col md="6">
                          <FormGroup>
                            <label>Patient Id</label>
                            <Input
                              placeholder="Patient Id"
                              type="text"
                              name="patientId"
                              value={values.patientId}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      }
                      <Col md="6">
                        <FormGroup>
                          <label>Patient Name</label>
                          <Input
                            placeholder="Patient Name"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>


                      <Col md="6">
                        <FormGroup>
                          <label>Patient Age</label>
                          <Input
                            placeholder="Patient Age"
                            name="age"
                            value={values.age}
                            onChange={handleChange}
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Patient Gender</label>
                          <Input
                            name="gender"
                            onChange={handleChange}
                            value={values.gender}
                            placeholder="Patient Gender"
                            type="text"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>Doctor Reference</label>
                          <Input
                            name="doctorReferenceFromUser"
                            onChange={handleChange}
                            value={values.doctorReferenceFromUser}
                            placeholder="Doctor Reference"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {!params.id &&
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Prescription </label>
                            <input
                              type="file"
                              className="form-control"
                              onChange={saveFile}
                              disabled={fileUploading} // Disable the input when file uploading
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    }
                    {!params.id &&
                      <Row>
                        <Col md="12">
                          <label>Reason for Scan </label>
                          <MultiSelect
                            options={options}
                            value={selected}
                            onChange={setSelected}
                            labelledBy="Select"
                          />
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Comment</label>
                          <textarea
                            name="comment"
                            className="form-control"
                            value={values.comment}
                            placeholder="Enter your comment here..."
                            onChange={handleChange}
                          ></textarea>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          onClick={handleFormSubmit}
                          disabled={isButtonDisabled} // Disable the button based on state
                        >
                          {params.id ? 'Edit' : 'Add'} Patient
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {fileUploading && (
          <Row>
            <Col md="12">
              <Progress value={uploadProgress} />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default User;
